import styles from "../coursesprogress.module.css";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import React from "react";
import {Link} from "react-router-dom";
import {ROLES, useUserContext} from "../../../../Components/UserContext/UserContext";
import {useTranslation} from "react-i18next";

function GroupProgress({index, title, users, usersCount, groupId, isDisabled}) {

    const { user } = useUserContext();
    const { i18n } = useTranslation()

    const linkTo = `/${user?.role.toLowerCase()}/myGroup/${groupId}${user?.role === ROLES.ADMIN ? '/members' : ''}`;

    const content = (
        <>
            <div className={styles.imageCont}>
                <img src={'/images/coursesInProgress.png'} className={styles.image} alt={'coursesInProgress'} />
                <span className={styles.number}>Group {index}</span>
            </div>
            <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{title}</p>
            <div className={styles.avatar}>
                <p className={styles.students}>{usersCount} {usersCount > 1 ? 'students' : 'student'}</p>
            </div>
        </>
    );

    return (
        isDisabled ? (
            <div className={styles.isDisabledGroup}>{content}</div>
        ) : (
            <Link to={linkTo} className={styles.group}>
                {content}
            </Link>
        )
    );
}

export default GroupProgress;