import React from "react";
import styles from './totalamount.module.css'
function TotalAmount({statistics, titleKeyMap}) {

    const sections = Object.keys(titleKeyMap).map(title => ({
        title,
        count: statistics[titleKeyMap[title]] || 0
    }));

    return (
        <div className={styles.container}>
            <p className={styles.title}>Total amount</p>
            <div className={styles.sections}>
                {sections.map(i => (
                    <div key={i.title} className={`${styles.section} ${styles[i.title.toLowerCase()]}`}>
                        <p className={styles.number}>{i.count}</p>
                        <img className={styles.icon} src={`/images/${i.title.toLowerCase()}Section.png`} alt={i.title} />
                        <p className={styles.sectionTitle}>{i.title}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TotalAmount;