import React, {useEffect, useState} from "react";
import CoursesInProgress from "../../TeacherDashboard/Teacher/CoursesInProgress/CoursesInProgress";
import TotalAmount from "./TotalAmount/TotalAmount";
import axios from '../../axios/axios'

const titleKeyMap = {
    'Admins': 'adminCount',
    'Teachers': 'teacherCount',
    'Students': 'studentCount',
    'Courses': 'courseCount',
    'Groups': 'groupCount',
    'Subscriptions': 'subscriberCount'
};
function SuperAdminDashboard() {

    const [statistics, setStatistics] = useState({});

    useEffect(() => {
        axios.get('/dashboard/getSuperAdminStatistics')
            .then(({ data }) => setStatistics(data));
    }, []);

    return (
      <>
          <TotalAmount statistics={statistics} titleKeyMap={titleKeyMap} />
          <CoursesInProgress isDisabled />
      </>
    )
}

export default SuperAdminDashboard;