import React, {useEffect, useState} from "react";
import styles from './coursesprogress.module.css'
import GroupProgress from "./GroupProgress/GroupProgress";
import axios from '../../../axios/axios'
import {useTranslation} from "react-i18next";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";
import {isDisabled} from "@testing-library/user-event/dist/utils";


function CoursesInProgress({isDisabled}) {

    const [groups, setGroups] = useState([])
    const {t, i18n} = useTranslation()
    const {user} = useUserContext();

    const rolesToUrls = {
        [ROLES.SUPERADMIN]: 'getAllGroupForSuperAdminDashbord',
        [ROLES.ADMIN]: 'getAllGroupForAdminDashbord',
        [ROLES.TEACHER]: 'getAllGroupForTeacherDashbord',
    };

    const groupUrl = rolesToUrls[user?.role] ?? 'findGroups';

   useEffect(() => {
       axios.get(`Group/${groupUrl}`)
           .then(({data} )=> setGroups(data?.group)).catch((e) => console.log(e))
   }, [groupUrl])

    return(
        <div className={styles.container}>
            <p className={`${styles.title} ${styles[`title_${i18n.language}`]}`}>{t('admin.dashboard.groupsInProgress')}</p>
            <div className={styles.groups}>
                {groups.map((group, i) =>
                    <GroupProgress
                        index={i+1}
                        groupId={group?.id}
                        title={group.name}
                        users={group.GroupsPerUsers}
                        usersCount={group.usersCount}
                        isDisabled={isDisabled}
                    />
                )}
            </div>
        </div>
    )
}

export default CoursesInProgress;