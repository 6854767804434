import React, { useEffect, useState } from "react";
import styles from './admindashboard.module.css';
import CoursesInProgress from "../../TeacherDashboard/Teacher/CoursesInProgress/CoursesInProgress";
import DashCalendar from "../../Dashboard/Home/Calendar/DashCalendar";
// import CreateNew from "./CreateNew/CreateNew";
import axios from "../../axios/axios";
import TotalAmount from "../../SuperAdmin/SuperAdminDashboard/TotalAmount/TotalAmount";

const titleKeyMap = {
    'Teachers': 'teachersCount',
    'Students': 'studentsCount',
    'Groups': 'groupsCount'
};

function AdminDashboard() {
    const [statistics, setStatistics] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get('/dashboard/getAdminStatistics');
                setStatistics(data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            {statistics && <TotalAmount statistics={statistics} titleKeyMap={titleKeyMap} /> }
            <section className={styles.section}>
                <div className={styles.container + ' ' + styles.first}>
                    {/* <div className={styles.section}> */}
                    {/*     <CreateNew /> */}
                    {/* </div> */}
                    <CoursesInProgress />
                </div>
                <div className={styles.container + ' ' + styles.second}>
                    <DashCalendar />
                </div>
            </section>
        </>
    );
}

export default AdminDashboard;