import React, { useEffect, useState } from 'react';
import styles from "./AdminUsers.module.css";
import { Link } from "react-router-dom";
import AdminAddNewMember from "./AdminAddNewMember/AdminAddNewMember";
import EditDelete from "./EditDelete/EditDelete";
import axios from "../../../axios/axios";
import Loader from "../../../Components/Loader/Loader";
import { CiSearch } from "react-icons/ci";
import Button from "../../../Components/Button/button";
import {ROLES, useUserContext} from "../../../Components/UserContext/UserContext";

function AdminUsers() {
    const [update, setUpdate] = useState(true);
    const [isProfessionalModalOpen, setProfessionalModalOpen] = useState(false);
    const [userToDeleteId, setUserToDeleteId] = useState(null);
    const [delet, setDelet] = useState(false);
    const [actionType, setActionType] = useState('');
    const [users, setUsers] = useState();
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState();
    const { user } = useUserContext();

    const openProfessionalModal = () => setProfessionalModalOpen(true);
    const closeProfessionalModal = () => setProfessionalModalOpen(false);
    const closForEditDelete = () => setDelet(false);

    const openForEditDelete = (type, id) => {
        setActionType(type);
        setDelet(true);
        setUserToDeleteId(id);
    };

    const handleEdit = () => closForEditDelete();

    const onUpdate = () => setUpdate(prevState => !prevState);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`/register/getMembers${user?.role === ROLES.SUPERADMIN ? 'SuperAdmin' : ''}`);
                setUsers(response.data);
                setFilteredUsers(response.data);
            } catch (error) {
                console.error('Error fetching user courses:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [update]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (users) {
                const filtered = {
                    students: users.students.filter((s) =>
                        `${s.firstName} ${s.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
                    ),
                    teachers: users.teachers.filter((t) =>
                        `${t.firstName} ${t.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
                    ),
                };
                setFilteredUsers(filtered);
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, users]);

    useEffect(() => {
        const lastStudentId = sessionStorage.getItem('lastStudentId');
        if (lastStudentId && filteredUsers?.students) {
            const studentElement = document.querySelector(
                `[data-student-id="${lastStudentId}"]`
            );
            if (studentElement) {
                studentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            sessionStorage.removeItem('lastStudentId');
        }
    }, [filteredUsers]);

    const handleDelete = async (userId) => {
        try {
            await axios.delete(`/register/deleteMembers/${userId}`);
            setUpdate(prevState => !prevState);
        } catch (error) {
            console.error('Error deleting user:', error);
        }
        closForEditDelete();
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <div className={styles.total}>
                        <div className={styles.searchContainer}>
                            <input
                                type="text"
                                placeholder="Search by username"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className={styles.searchBar}
                            />
                            <CiSearch className={styles.searchIcon} />
                        </div>
                        <Button className={'primary'} text={"Add new member"} handleButtonClick={openProfessionalModal} />
                    </div>
                    <div className={styles.container}>
                        <div className={styles.left}>
                            <div className={styles.total}>
                                <div className={styles.totalon}>
                                    <p className={styles.on}>Students</p>
                                    <p className={styles.to}>totally {filteredUsers?.students.length} student(s)</p>
                                </div>
                            </div>
                            <div className={styles.cont}>
                                <div className={styles.scroll}>
                                    {filteredUsers?.students.map((i, index) => (
                                        <div
                                            key={index}
                                            className={styles.titListmap}
                                            data-student-id={i.id}
                                        >
                                            <div className={styles.ones}>
                                                <Link
                                                    to={`/${user?.role.toLowerCase()}/users/student/${i.id}`}
                                                    className={styles.link}
                                                    onClick={() => sessionStorage.setItem('lastStudentId', i.id)}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundImage: `url(${'https://platform.tesvan.com/server/' + i?.image})`,
                                                            backgroundPosition: 'center',
                                                            backgroundSize: 'cover',
                                                        }}
                                                        className={styles.imgsstudents}
                                                    ></div>
                                                    <div>
                                                        <p className={styles.studentname}>
                                                            {i.firstName} {i.lastName}
                                                        </p>
                                                        <p className={styles.studentname1}>
                                                            {i.createdAt.split('T')[0]}
                                                        </p>
                                                    </div>
                                                </Link>
                                                <div>
                                                    <Link to={`/${user?.role.toLowerCase()}/users/student/${i.id}`}>
                                                        <img
                                                            className={styles.points}
                                                            src={'/images/paniconuser.png'}
                                                            alt={'pan'}
                                                        />
                                                    </Link>
                                                    <img
                                                        onClick={() => openForEditDelete('delete', i.id)}
                                                        alt={'delete'}
                                                        src={'/images/basketiconuser.png'}
                                                        className={styles.points}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.total}>
                                <div className={styles.totalon}>
                                    <p className={styles.on}>Teachers</p>
                                    <p className={styles.to}>totally {filteredUsers?.teachers.length} teacher(s)</p>
                                </div>
                            </div>
                            <div className={styles.cont}>
                                <div className={styles.scroll}>
                                    {filteredUsers?.teachers.map((i, index) => (
                                        <div key={index} className={styles.titListmap}>
                                            <div className={styles.ones}>
                                                <Link to={`/${user?.role.toLowerCase()}/users/teacher/${i.id}`} className={styles.link}>
                                                    <div
                                                        style={{
                                                            backgroundImage: `url(${'https://platform.tesvan.com/server/' + i?.image})`,
                                                            backgroundPosition: 'center',
                                                            backgroundSize: 'cover',
                                                        }}
                                                        className={styles.imgsstudents}
                                                    ></div>
                                                    <div>
                                                        <p className={styles.studentname}>
                                                            {i.firstName} {i.lastName}
                                                        </p>
                                                        <p className={styles.studentname1}>
                                                            {i.createdAt.split('T')[0]}
                                                        </p>
                                                    </div>
                                                </Link>
                                                <div>
                                                    <Link to={`/${user?.role.toLowerCase()}/users/teacher/${i.id}`}>
                                                        <img
                                                            className={styles.points}
                                                            src={'/images/paniconuser.png'}
                                                            alt={'pan'}
                                                        />
                                                    </Link>
                                                    <img
                                                        onClick={() => openForEditDelete('delete', i.id)}
                                                        alt={'delete'}
                                                        src={'/images/basketiconuser.png'}
                                                        className={styles.points}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <AdminAddNewMember
                update={onUpdate}
                isOpen={isProfessionalModalOpen}
                closeModal={closeProfessionalModal}
            />
            <EditDelete
                isOpen={delet}
                closeModal={closForEditDelete}
                actionType={actionType}
                handleEdit={handleEdit}
                handleDelete={() => handleDelete(userToDeleteId)}
            />
        </>
    );
}

export default AdminUsers;
