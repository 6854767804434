import React, { Fragment, useEffect, useState } from "react";
import styles from './adminstable.module.css';
import axios from "../../axios/axios";
import { RiDeleteBin6Line } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import AdminAddingModal from "./AdminAddingModal/AdminAddingModal";
import Button from "../../Components/Button/button";

const tHeads = ['Admin Name', 'Group Count', 'Teacher Count', 'User Count', 'Status'];
function AdminsTable() {
    const [admins, setAdmins] = useState([]);
    const [isOpened, setIsOpened] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        axios.get('/admin').then(({ data }) => setAdmins(data?.admins));
    }, [isUpdated]);

    const handleAdminEdit = id => {
        axios.get(`/admin/${id}`).then(({ data }) => {
            setSelectedAdmin(data?.admin);
            setIsOpened(true);
            setIsEditing(true);
        });
    };

    const handleAdminView = (id) => {
        axios.get(`/admin/${id}`).then(({ data }) => {
            setSelectedAdmin(data?.admin);
            setIsOpened(true);
            setIsEditing(false);
        });
    };

    const handleAdminDelete = (adminId) => {
        axios.delete(`/admin/${adminId}`)
            .then(() => setAdmins((prevAdmins) => prevAdmins.filter((admin) => admin.id !== adminId)))
    };

    return (
        <>
            {isOpened && (
                <AdminAddingModal
                    isOpened={isOpened}
                    setIsOpened={setIsOpened}
                    selectedAdmin={selectedAdmin}
                    readOnly={!isEditing}
                    setIsUpdated={setIsUpdated}
                />
            )}
            <div className={styles.buttonContainer}>
                <p className={styles.title}>Admin Overview</p>
                <Button className={'primary'} handleButtonClick={() => {
                    setSelectedAdmin(null);
                    setIsOpened(true);
                    setIsEditing(true);
                }} text={'Add a new admin'} />
            </div>
            <table className={styles.table}>
                <thead>
                <tr>
                    {tHeads.map((name, i) => (
                        <th key={i}>
                            <span>{name}</span>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {admins?.map((admin) => (
                    <Fragment key={admin.id}>
                        <tr>
                            <td>
                                <div className={`${styles.user} ${styles.bigGap}`}>
                                    <p className={styles.username} onClick={() => handleAdminView(admin?.id)}>{admin.firstName} {admin.lastName}</p>
                                    <div className={`${styles.user} ${styles.smallGap}`}>
                                        <BiEditAlt className={styles.icon} onClick={() => handleAdminEdit(admin?.id)} />
                                        <RiDeleteBin6Line className={styles.icon} onClick={() => handleAdminDelete(admin?.id)} />
                                    </div>
                                </div>
                            </td>
                            <td>{admin.groupCount}</td>
                            <td>{admin.teacherCount}</td>
                            <td>{admin.userCount}</td>
                            <td>{admin.isActive ? 'Active' : 'Inactive'}</td>
                        </tr>
                    </Fragment>
                ))}
                </tbody>
            </table>
        </>
    )
}

export default AdminsTable;