import React, { useState, useEffect } from "react";
import styles from './adminaddingmodal.module.css';
import { RiArrowDownSLine } from "react-icons/ri";
import Country from "../../../Components/Country/Country";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import CustomDate from "../../../Components/Date/Date";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import axios from "../../../axios/axios";
import Button from "../../../Components/Button/button";

const nameRegex = /^[a-zA-Z\s\-']+$/;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const cityRegex = /^[a-zA-Z\s\-']+$/;

function AdminAddingModal({ isOpened, setIsOpened, selectedAdmin, readOnly, setIsUpdated }) {
    const [admin, setAdmin] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        date: '',
        gender: '',
        country: '',
        city: ''
    });

    useEffect(() => {
        if (selectedAdmin) {
            setAdmin({
                firstName: selectedAdmin.firstName,
                lastName: selectedAdmin.lastName,
                email: selectedAdmin.email,
                phone: selectedAdmin.phoneNumber,
                date: selectedAdmin.birthday,
                gender: selectedAdmin.gender,
                country: selectedAdmin.country,
                city: selectedAdmin.city
            });
        } else {
            setAdmin({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                date: '',
                gender: '',
                country: '',
                city: ''
            });
        }
    }, [selectedAdmin]);

    const [open, setOpen] = useState(false);
    const [phoneErr, setPhoneErr] = useState(null);
    const [nameErr, setNameErr] = useState(null);
    const [lastNameErr, setLastNameErr] = useState(null);
    const [emailErr, setEmailErr] = useState(null);
    const [cityErr, setCityErr] = useState(null);
    const [dateErr, setDateErr] = useState(null);
    const [emailConflictErr, setEmailConflictErr] = useState(null); // New state for email conflict error
    const gen = ['Male', 'Female'];

    const validatePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return null;

        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        if (!parsedNumber) return false;

        const countryCodeLength = parsedNumber.countryCallingCode.length + 1;
        if (phoneNumber.length <= countryCodeLength) return null;

        return parsedNumber.isValid();
    };

    const onChange = (e) => {
        const { name, value } = e.target;
        setAdmin(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async () => {
        setEmailConflictErr(null); // Reset email conflict error before making the request

        if (selectedAdmin) {
            try {
                const response = await axios.patch(`/admin/${selectedAdmin.id}`, {
                    firstName: admin.firstName,
                    lastName: admin.lastName,
                    email: admin.email,
                    phoneNumber: '+' + admin.phone.substring(1).replace(/\s+/g, ""),
                    birthday: admin.date,
                    gender: admin.gender,
                    country: admin.country,
                    city: admin.city,
                });

                if (response.status === 200) {
                    setIsOpened(false);
                    setIsUpdated(prevState => !prevState);
                }
            } catch (error) {
                if (error.response && error.response.status === 400 && error.response.data.message === "Email already in use") {
                    setEmailConflictErr("Email is already registered.");
                } else {
                    console.error('Error updating admin: ', error);
                }
            }
        } else {
            try {
                const response = await axios.post('/admin', {
                    firstName: admin.firstName,
                    lastName: admin.lastName,
                    email: admin.email,
                    phoneNumber: '+' + admin.phone.substring(1).replace(/\s+/g, ""),
                    birthday: admin.date,
                    gender: admin.gender,
                    country: admin.country,
                    city: admin.city,
                    role: "ADMIN"
                });

                if (response.status === 200 || response.status === 201) {
                    setAdmin({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        date: '',
                        gender: '',
                        country: '',
                        city: ''
                    });
                    setIsOpened(false);
                    setIsUpdated(prevState => !prevState);
                } else {
                    console.error('Error creating admin: ', response.data);
                }
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setEmailConflictErr("Email is already registered.");
                    setEmailErr('');
                } else {
                    console.error('Error creating admin: ', error);
                }
            }
        }
    };

    return (
        isOpened ? (
            <div onClick={() => setIsOpened(false)} className={styles.modal}>
                <div onClick={(e) => e.stopPropagation()} className={styles.parent}>
                    <div className={styles.formContainer}>
                        <div className={`${styles.container} ${styles.containerSettings}`}>
                            <p className={styles.title}>{selectedAdmin ? (readOnly ? 'View Admin' : 'Edit Admin') : 'Add a new admin'}</p>
                            <div className={styles.inputSettings}>
                                <span className={styles.caption}>First name</span>
                                {readOnly ? (
                                    <p className={styles.readOnlyText}>{admin.firstName}</p>
                                ) : (
                                    <input
                                        type="text"
                                        className={`${styles.input} ${nameErr === null ? "" : nameErr ? styles.errorinp : styles.noterrinp}`}
                                        value={admin.firstName}
                                        placeholder="John"
                                        name="firstName"
                                        onChange={onChange}
                                        onBlur={() => {
                                            setNameErr(!nameRegex.test(admin.firstName));
                                        }}
                                    />
                                )}
                                {nameErr && <p className={styles.error}>First name is not valid</p>}
                            </div>
                            <div className={styles.inputSettings}>
                                <span className={styles.caption}>Last name</span>
                                {readOnly ? (
                                    <p className={styles.readOnlyText}>{admin.lastName}</p>
                                ) : (
                                    <input
                                        type="text"
                                        className={`${styles.input} ${lastNameErr === null ? "" : lastNameErr ? styles.errorinp : styles.noterrinp}`}
                                        value={admin.lastName}
                                        placeholder="Smith"
                                        name="lastName"
                                        onChange={onChange}
                                        onBlur={() => {
                                            setLastNameErr(!nameRegex.test(admin.lastName));
                                        }}
                                    />
                                )}
                                {lastNameErr && <p className={styles.error}>Last name is not valid</p>}
                            </div>
                            <div className={styles.inputSettings}>
                                <span className={styles.caption}>Email</span>
                                {readOnly ? (
                                    <p className={styles.readOnlyText}>{admin.email}</p>
                                ) : (
                                    <>
                                        <input
                                            type="text"
                                            className={`${styles.input} ${(emailErr === null && !emailConflictErr) ? "" : (emailErr || emailConflictErr) ? styles.errorinp : styles.noterrinp}`}
                                            value={admin.email}
                                            placeholder="johnsmith@example.com"
                                            name="email"
                                            onChange={onChange}
                                            onBlur={() => {
                                                setEmailErr(!emailRegex.test(admin.email));
                                            }}
                                        />
                                        {emailErr && <p className={styles.error}>Email is not valid</p>}
                                        {!emailErr && emailConflictErr && <p className={styles.error}>{emailConflictErr}</p>}
                                    </>
                                )}
                            </div>
                            <div className={styles.inputSettings} id={'idData'}>
                                <span className={styles.caption}>Date of birth</span>
                                {!readOnly ? (
                                    <div style={{ width: '48%' }}>
                                        <CustomDate putDate={(newDate) => onChange({ target: { name: 'date', value: newDate } })} selectedDate={admin.date} error={setDateErr} />
                                        {dateErr && <p className={styles.error}>Birth Date is not valid</p>}
                                    </div>
                                ) : (
                                    <p className={styles.readOnlyText}>{admin.date.split("T", 1)[0]}</p>
                                )}
                            </div>
                            <div className={styles.inputSettings}>
                                <span className={styles.caption}>Country</span>
                                <div className={styles.contry}>
                                    {readOnly ? (
                                        <p className={styles.readOnlyText}>{admin.country}</p>
                                    ) : (
                                        <>
                                            <input
                                                className={styles.inpcont}
                                                style={{ cursor: 'pointer' }}
                                                placeholder={'Select your country'}
                                                type="text"
                                                value={admin.country}
                                                name="country"
                                                readOnly={true}
                                                onClick={() => setOpen(!open)}
                                            />
                                            <RiArrowDownSLine
                                                className={!open ? styles.arrow : `${styles.arrow} ${styles.arrowhov}`}
                                            />
                                            {open &&
                                                <Country value={(selectedCountry) => onChange({ target: { name: 'country', value: selectedCountry } })} setOpen={setOpen} />
                                            }
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={styles.inputSettings}>
                                <span className={styles.caption}>Phone number</span>
                                {readOnly ? (
                                    <p className={styles.readOnlyText}>{admin.phone}</p>
                                ) : (
                                    <PhoneInput
                                        defaultCountry="am"
                                        value={admin.phone}
                                        name="phoneInput"
                                        className={`${styles.phone} ${phoneErr !== null && phoneErr ? styles.phoneerr : ''}`}
                                        onChange={(phone) => onChange({ target: { name: 'phone', value: phone } })}
                                        onBlur={() => {
                                            setPhoneErr(admin.phone ? !validatePhoneNumber(admin.phone) : null);
                                        }}
                                    />
                                )}
                                {phoneErr && <p className={styles.error}>Phone number is not valid</p>}
                            </div>
                            <div className={styles.inputSettings}>
                                <span className={styles.caption}>Gender</span>
                                {readOnly ? (
                                    <p className={styles.readOnlyText}>{admin.gender}</p>
                                ) : (
                                    <div className={`${styles.input} ${styles.radio}`}>
                                        {gen.map((i, index) => (
                                            <div key={index}>
                                                <RadioButton
                                                    text={i}
                                                    value={i}
                                                    name="gender"
                                                    checked={admin.gender === i}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className={styles.inputSettings}>
                                <span className={styles.caption}>City</span>
                                {readOnly ? (
                                    <p className={styles.readOnlyText}>{admin.city}</p>
                                ) : (
                                    <input
                                        type="text"
                                        className={`${styles.input} ${cityErr === null ? "" : cityErr ? styles.errorinp : styles.noterrinp}`}
                                        value={admin.city}
                                        placeholder="Where do you live?"
                                        name="city"
                                        onChange={onChange}
                                        onBlur={() => {
                                            setCityErr(!cityRegex.test(admin.city));
                                        }}
                                    />
                                )}
                                {cityErr && <p className={styles.error}>City is not valid</p>}
                            </div>
                            <div className={styles.inputSettings}>
                                {readOnly ? (
                                    <Button className={'primary'} text={'Close'} handleButtonClick={() => setIsOpened(false)} styles={{ margin: '0 auto 20px', width: '200px' }} />
                                ) : (
                                    <Button className={'primary'} text={selectedAdmin ? 'Edit' : 'Submit'} handleButtonClick={handleSubmit} styles={{ margin: '0 auto 20px', width: '200px' }} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : null
    );
}

export default AdminAddingModal;