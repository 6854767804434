import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export const ROLES = {
    SUPERADMIN: "SUPERADMIN",
    ADMIN: "ADMIN",
    STUDENT: "STUDENT",
    TEACHER: "TEACHER",
}

export const useUserContext = () => {
    return useContext(UserContext);
};

export const UserProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [status, setStatus] = useState('waiting')


    const contextValue = {
        user,
        setUser,
        status,
        setStatus
    };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};
