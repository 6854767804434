import React, { Fragment, useEffect, useState } from "react";
import styles from './superadminsubscriptions.module.css';
import axios from "../../axios/axios";
import moment from "moment";
import Button from "../../Components/Button/button";

const tHeads = ['Name Surname', 'Amount', 'Expiry date'];
function SuperAdminSubscriptions() {
    const [subscriptions, setSubscriptions] = useState([]);
    const [adminPayments, setAdminPayments] = useState({});
    const [expandedUserIds, setExpandedUserIds] = useState([]);

    useEffect(() => {
        axios.get('/payment/getAllSubscriptionsForSuperAdmin').then(({ data }) => setSubscriptions(data.subscriptions || []));
    }, []);

    const handleUserClick = userId => {
        setExpandedUserIds((prev) =>
            prev.includes(userId) ? prev.filter((id) => id !== userId) : [...prev, userId]
        );

        if (!expandedUserIds.includes(userId)) {
            axios.get(`/payment/getAdminPaymentsForSuperAdmin/${userId}`).then(({ data }) => {
                setAdminPayments((prev) => ({
                    ...prev,
                    [userId]: data?.payments || [],
                }));
            });
        }
    };

    const handleDownloadInvoice = (paymentId, firstName, lastName) => {
        axios
            .get(`/payment/downloadInvoice`, {
                params: { paymentId },
                responseType: 'blob',
            })
            .then(({ data }) => {
                if (data.size === 0) {
                    return;
                }
                const blob = new Blob([data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                const filename = `Invoice_${firstName}_${lastName}_${paymentId}.pdf`;

                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.log("Error downloading invoice:", error);
            });
    };

    return (
        <>
            <p className={styles.title}>Admin Payments</p>
            <table className={styles.table}>
                <thead>
                <tr>
                    {tHeads.map((name, i) => (
                        <th key={i}>
                            <span>{name}</span>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {subscriptions.map((payment) => (
                    <Fragment key={payment?.id}>
                        <tr>
                            <td>
                                <span
                                    onClick={() => handleUserClick(payment?.id)}
                                    className={styles.adminName}
                                >
                                    <span
                                        className={`${styles.arrow} ${
                                            expandedUserIds.includes(payment?.id) ? styles.down : styles.right
                                        }`}
                                    >
                                        ▶
                                    </span>
                                    {payment?.firstName} {payment?.lastName}
                                </span>
                            </td>
                            <td>{payment?.amount} AMD</td>
                            <td>{moment(payment?.endDate).format("DD MMM, YYYY")}</td>
                        </tr>
                        {expandedUserIds.includes(payment?.id) && (
                            <tr>
                                <td colSpan={3} style={{ padding: 0 }}>
                                    <table className={styles.subTable} style={{ width: "100%" }}>
                                        <thead>
                                        <tr>
                                            <th>Payment Way</th>
                                            <th>Status</th>
                                            <th>Amount</th>
                                            <th>Type</th>
                                            <th>Date</th>
                                            <th>Invoice</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {adminPayments[payment?.id] ? (
                                            adminPayments[payment?.id].map((detail) => (
                                                <tr key={detail?.id}>
                                                    <td>{detail?.paymentWay}</td>
                                                    <td>
                                                        <div
                                                            className={`${styles.status} ${
                                                                detail.status === "Success"
                                                                    ? styles.paid
                                                                    : styles.failed
                                                            }`}
                                                        >
                                                            {detail.status}
                                                        </div>
                                                    </td>
                                                    <td>{detail?.amount} AMD</td>
                                                    <td style={{ textTransform: "capitalize" }}>{detail?.type}</td>
                                                    <td>{moment(detail?.updatedAt).format("DD MMM, YYYY")}</td>
                                                    <td>
                                                        <Button
                                                            className={"primary"}
                                                            text={"Download"}
                                                            handleButtonClick={() => handleDownloadInvoice(detail?.id, payment?.firstName, payment?.lastName)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={5} style={{ textAlign: "center" }}>
                                                    No payment details available
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        )}
                    </Fragment>
                ))}
                </tbody>
            </table>
        </>
    );
}

export default SuperAdminSubscriptions;
